@import "setup.css";

.vo-nav-bar {

	&.mainHeader {
		position: fixed;
		background: rgba(255, 255, 255, 0.9);
		width: 100%;
		top: 0px;
		z-index: 99;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);

		.logo {
			position: absolute;
			background: #fff;
			border-radius: 0px 0px 50% 0px;
			box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);

			a {
				padding: 10px 30px 25px 10px;
			}

			img {
				max-height: 200px;
				transform: rotate(-30deg);
			}
		}

		.topNav {
			.navbar-toggler {
				background: #215d90;
				font-size: 22px;
				color: #ffffff;
				padding: 5px 12px;
				border: none;
				box-shadow: none;
				display: none;
			}

			.navbar-right {
				margin: 20px 0px;
				min-height: 20px;
				border: none;

				.menu-text {
					display: none;
				}
			}

			ul {
				margin: 0px 20px 0px 0px;
				padding: 0px;

				li {
					list-style: none;
					margin: 0px;
					padding: 0px;
					margin: 0px 20px;

					a {
						font-size: 18px;
						color: #215d90;
						padding: 5px;
						background: transparent;
						text-align: left;

						&:hover {
							color: #222222;
						}
					}

					&:hover {
						.dropdown-menu {
							display: block;
						}
					}

					.dropdown-menu {
						min-width: 200px;
						left: 0px;
						margin: 0px;
						padding: 10px 0px;

						li {
							float: left;
							width: 100%;
							margin: 0px;
							padding: 0px 15px;
						}

						a {
							font-size: 16px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1600px) {
			.logo {
				img {
					max-height: 110px;
				}
			}
		}

		@media screen and (max-width: 989px) {
			.logo {
				img {
					max-height: 100px;
				}
			}

			.topNav {
				ul {
					li {
						margin: 0px 5px;
					}
				}
			}
		}

		@media screen and (max-width: 767px) {
			.logo {
				a {
					padding: 10px;
				}
			}

			.topNav {
				.navbar-toggler {
					display: block;
					position: relative;
					float: right;
					top: 0;
					right: 20px;
					z-index: 1;
				}

				.navbar-right {
					border: none;
				}

				.navbar-collapse {
					position: fixed;
					left: unset;
					right: 0;
					top: 0;
					background: #004279;
					bottom: 0;
					height: 480px;
					overflow: auto;
					width: 100%;
					padding: 50px 0 0;

					.menu-text {
						position: absolute;
						top: 15px;
						color: #fff;
						font-size: 20px;
						left: 20px;
						display: block;
						margin: 0;
					}

					ul {
						magin: 0px;
						float: left;
						width: 100%;
						padding: 10px 15px;
						height: 100%;

						li {
							float: left;
							width: 100%;
							margin: 0;
							border-bottom: 1px solid rgb(255 255 255 / 65%);
							padding: 7px 0 7px 0;

							a {
								color: #ffffff;
							}

							&:hover {
								a {
									color: #ffffff;
								}

								.dropdown-menu {
									display: none;
								}
							}

							&.open {
								.dropdown-menu {
									display: block;
								}
							}

							&:last-child {
								border: none !important;
							}
						}
					}
				}
			}
		}
	}

	a {
		float: left;
		display: block;
		color: #f2f2f2;
		text-align: center;
		text-decoration: none;
		font-size: 17px;
	}

	.active {
		background-color: #4CAF50;
		color: white;
	}

	.icon {
		display: none;
	}

	.vo-dropdown,
	.input-group {
		float: left;
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}

	.vo-dropdown .dropbtn {
		font-size: 17px;
		border: none;
		outline: none;
		color: white;
		padding: 14px 16px;
		background-color: inherit;
		font-family: inherit;
		margin: 0;
	}

	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	.vo-dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
	}

	a:hover,
	.vo-dropdown:hover .dropbtn {}

	.vo-dropdown-content a:hover {
		background-color: #ddd;
		color: black;
	}


	.vo-dropdown:hover {
		color: red;
	}

	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.vo-dropdown .dropbtn {
			display: none;
		}

		a.icon {
			float: right;
			display: block;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}



}


@media screen and (max-width: var(--navbarbreak)) {
	.vo-nav-bar.opened {
		position: relative;
	}

	.vo-nav-bar.opened .icon {
		position: absolute;
		right: 0;
		top: 0;
	}

	.vo-nav-bar.opened a {
		float: none;
		display: block;
		text-align: left;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-nav-bar.opened .vo-dropdown-content {
		position: relative;
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}
}