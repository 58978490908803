@import "setup.css";

.indexpage {


	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.banner-btn {
		background: none repeat scroll 0 0 rgba(0, 0, 0, .6);
		padding: 15px 25px;
		position: absolute;
		bottom: -100px;
		left: 50%;
		transform: translate(-50%, 0);
	}

	.iheader {
		height: 100vh;
		min-height: 500px;

		@media (min-width:1200px) {
			/*min-height: 700px;*/
		}

		position: relative;

		.owl-carousel {
			width: 100%;
			z-index: -1;
			position: relative;
			height: 100vh;
			min-height: 500px;
			overflow: hidden;

			.owl-stage-outer {
				position: relative;
				overflow: hidden;
			}

			.owl-stage {
				position: relative;
				touch-action: manipulation;
				-moz-backface-visibility: hidden;
			}

			.owl-item {
				min-height: 1px;
				float: left;
				position: relative;
				-webkit-backface-visibility: hidden;
				-webkit-touch-callout: none;
			}
		}

		.banner-slide {

			.owl-dots,
			.owl-nav {
				display: none;
			}

			.item {
				height: 100vh;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				display: flex;
				align-items: center;
				/*padding: 0 15px;*/
				width: 100%;
				justify-content: center;
				min-height: 500px;

				@media (min-width:1200px) {
					min-height: 700px;
				}

				&.slide0 {
					background-image: url(RESOURCE/img/Startseite-img.jpg);
				}

				&.slide1 {
					background-image: url(RESOURCE/img/banner1.jpg);
				}

				&.slide2 {
					background-image: url(RESOURCE/img/banner2.jpg);
				}

			}
		}

		.intro {
			text-align: center;

			.hero {
				text-align: center;
				color: #fff;
				background: none;

				margin-bottom: 10px;
				padding-bottom: 0px;

				h1,
				h2 {
					text-shadow: -3px 0 3px rgba(0, 0, 0, .6), 0 3px 3px rgba(0, 0, 0, .6), 3px 0 3px rgba(0, 0, 0, .6), 0 -3px 3px rgba(0, 0, 0, .6);
				}

				h1 {

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 60px 0 0;
					text-shadow: 0 0 10px #000;

				}

				h2 {
					font-size: 24px;

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 20px 0 0;
					text-shadow: 0 0 10px #000;

				}

			}

		}


	}

	.bannerContent {
		position: absolute;
		top: 30%;
		left: 0px;
		right: 0px;
		text-align: center;
		z-index: 12;

		h2 {
			font-family: 'pristina';
			font-size: 82px;
			color: #ffffff;
			line-height: 84px;
			font-weight: normal;

			@media screen and (max-width: 989px) {
				font-size: 70px;
				line-height: 80px;
			}

			@media screen and (max-width: 767px) {
				font-size: 62px;
				line-height: 70px;
			}

			@media screen and (max-width: 639px) {
				font-size: 52px;
				line-height: 60px;
			}

			@media screen and (max-width: 479px) {
				font-size: 42px;
				line-height: 50px;
			}
		}
	}


	.bannerSearch {
		position: absolute;
		top: 50%;
		left: 0px;
		right: 0px;


		.searchbar {

			margin-top: 20px;
			background: none repeat scroll 0 0 rgba(0, 0, 0, 0.6);
			padding: 15px 25px;

			.searchbox {
				padding: 5px;

				input,
				.search-btn,
				select {
					border-radius: 0;
				}

				.form-control {
					box-shadow: none;
					border: none;
				}

				.search-popup-trigger {
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					position: relative;
					background: url(RESOURCE/img/dropdown-icon.png) no-repeat center right 12px;

				}

				.search-btn {
					width: 100%;
					background: #215d90;
					border: none;
					box-shadow: none;

					&:hover {
						background: #f7c21c;
					}
				}

				.search-popup-trigger {
					cursor: pointer;
				}

				.form-control:disabled,
				.form-control[readonly] {
					background-color: white;
				}
			}

			@media (min-width:768px) {
				max-width: 650px;
			}

			@media (min-width:993px) {
				max-width: 950px;
			}

		}

		@media screen and (max-width: 767px) {
			left: 20px;
			right: 20px;

			.asd__wrapper--full-screen {
				top: 0;
				overflow: auto;
				z-index: 12345;
			}
		}

		@media screen and (max-width: 480px) {
			.box.full-xxs {
				top: 0 !important;
			}
		}
	}


	.contact-section {
		background-color: #fff !important;
	}


	@media (max-width:767px) {

		.iheader {

			background-size: cover;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}






}