.indexpage {
	.brief {
		padding-top: 45px;
		padding-bottom: 45px;
		line-height: 28px;
		font-size: 16px;


		.content-section {
			margin-top: 15px;
		}

		h1 {
			font-size: 32px;
			line-height: 38px;
			color: #215d90;
			font-weight: 700;
			margin: 0px 0px 15px 0px;
		}

		h2 {
			margin-bottom: 15px;
			margin-top: 10px;
		}

		p {
			margin-top: 22px;
			color: #555;
		}

		ul {
			color: #555;

			.fa {
				padding-right: 10px;
			}

			a {
				color: inherit;
			}
		}

		.brief-image-right {
			margin-top: 20px;
		}

		.brief-image-right img {
			float: right;
			width: 92%;
		}

		.brief-image-left {
			margin-top: 25px;
			overflow: hidden;
		}

		.brief-image-left img {
			float: left;
			width: 95%;
		}

		@media screen and (max-width: 767px) {
			h1 {
				font-size: 24px;
				line-height: 30px;
			}

			.btn.btn-primary {
				white-space: inherit;
			}
		}


	}

	.grey-bg {
		border-top: 1px solid #eaebec;
		border-bottom: 1px solid #eaebec;
		background: #F7F8FA;
	}

	.leftRightCont {
		padding: 60px 0;
		font-size: 16px;
		line-height: 28px;

		&.leftImgCol {
			background: #f7f8fa;
		}

		h2 {
			font-size: 28px;
			line-height: 38px;
			color: #215d90;
			font-weight: 700;
			margin: 0px 0px 15px 0px;

			small {
				font-size: 14px;
				line-height: 28px;
				color: #f7c21c;
				font-weight: 700;
				text-transform: uppercase;
				display: block;
			}
		}

		img {
			max-width: 100%;
		}
	}


	.feature-list {
		margin-top: 15px;
	}

	.feature-list li {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.feature-list li span {
		margin-right: 10px;
	}

	.colored-text {
		color: @introcolor;
	}

	/* COLORED BORDERS AND LINES */
	.colored-line-left,
	.colored-line-right,
	.colored-line {
		background: @introcolor;
	}

	.colored-line-left,
	.colored-line-right,
	.colored-line,
	.white-line,
	.grey-line {
		height: 2px;
		width: 80px;
		display: block;
	}

	.colored-line-left {
		float: left;
	}

	.colored-line-right {
		float: right;
	}

	.colored-line {
		margin: auto;
	}


	.small-text-medium {
		font-size: 14px;
		font-weight: 600;
	}

	.uppercase {
		text-transform: uppercase;
	}

	@media (max-width: 991px) {
		html {
			font-size: 90% !important;
		}

		.text-left,
		.text-right {
			text-align: center;
		}

		.colored-line-left {
			float: none;
			margin: auto;
		}


	}

}