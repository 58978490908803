.step2 {
	.confirmation {
		.fa-check {
			color: #215d90;
		}
	}
}

.booking-view {
	padding: 150px 0px 60px 0px;

	@media screen and (max-width: 1400px) {
		padding: 180px 0px 60px 0px;
	}

	@media screen and (max-width: 1200px) {
		padding: 200px 0px 60px 0px;
	}

	@media screen and (max-width: 989px) {
		padding: 150px 0px 60px 0px;
	}


	.page-header {
		margin: 0px 0px 20px 0px;

		h1 {
			font-size: 30px;
			color: #215d90;
			font-weight: 700;
			margin-top: 0px;
		}
	}

	.cart-details {

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
			}
		}
	}

	.pd-form {
		label {
			font-weight: 700;

			span {
				font-size: 16px;
				color: #ff0000;
				font-weight: 400;
			}
		}

		label.lrequired {
			font-weight: 700;
		}

	}

	.final-col {
		.form-group {
			margin-left: -15px;
			magin-right: -15px;
		}

		.checks {
			margin-bottom: 15px;

			label {
				font-weight: 700;
			}
		}

	}

	.flow-btn {
		margin-left: 20px;
		margin-top: 20px;
	}


}