@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

.doc-box {
	text-align: left;
}

@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


@font-face {
	font-family: 'pristina';
	src: url('RESOURCE/fonts/pristina-webfont.eot');
	src: url('RESOURCE/fonts/pristina-webfont.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/pristina-webfont.woff') format('woff'),
		url('RESOURCE/fonts/pristina-webfont.ttf') format('truetype'),
		url('RESOURCE/fonts/pristina-webfont.svg#pristina') format('svg');
	font-weight: normal;
	font-style: normal;

}

.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}

.btn.btn-primary,
.btn.btn-go {
	background: #215d90;
	border: 1px solid #215d90;
	color: #fff;
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
	padding: 10px 15px;

	&:hover {
		background: #f7c21c;
		border: 1px solid #f7c21c;
	}
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day--arrival {
		background: #fff;
		background: linear-gradient(135deg, #fff 50%, #eb5053 0) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: #3c9d46;
		background: linear-gradient(135deg, #eb5053 50%, #fff 0) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background-color: var(-hover-dt);
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background-color: var(-hover-dt);
	}

	.asd__day--disabled.asd__day--in-range {
		background: #BAD1E9 !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		background-color: var(--dt-green);
		cursor: pointer;
		color: #000 !important;

		color:var(--white) button {
			text-decoration: underline;
		}
	}

	.asd__day--enabled {
		background-color: var(--white);
		cursor: pointer;
		color: #000 !important;


		button {
			text-decoration: underline;
		}


		&.asd__day--disabled {

			button {
				text-decoration: none;
				cursor: not-allowed;
			}
		}

		&.asd__selected-date-one {
			background: rgb(0, 166, 153) !important;
			border: 1px double rgb(0, 166, 153) !important;
		}

		&.asd__day--not-available {
			background: var(--not-available) !important;
			opacity: 1 !important;
			cursor: not-allowed;
			color: #000 !important;
		}
	}


	.asd__day--in-range {
		background: #BAD1E9 !important;
	}

	/*.asd__selected-date-one {
		background-image: url(RESOURCE/img/date-start.png) !important;
		background-repeat: no-repeat !important;
		background-size: 13px !important;
		background-position: right center !important;
	}

	.asd__selected-date-two {
		background-image: url(RESOURCE/img/date-end.png) !important;
		background-repeat: no-repeat !important;
		background-size: 13px !important;
		background-position: left center !important;
	}*/
}

.legend-cal {
	text-align: center;
	padding: 0;
	margin: 15px 0;

	ul {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		//justify-content: center;
		padding: 0px;
		margin: 0px;
	}

	li {
		display: inline-flex;
		align-items: center;
		min-height: 30px;
		padding-right: 15px;
		margin: 5px 0;

		.day {
			width: 30px;
			height: 30px;
			background-color: #7e7975;
			display: inline-block;
			margin: 0 10px;

			&.day-free {
				background: rgba(230, 36, 40, .8);
			}

			&.day-full {
				/* background: rgba(230, 35, 41, .8);*/
				background: rgba(255, 255, 255, 1);
				border: 1px solid #ccc;
			}

			&.day-end {
				background: rgb(170, 126, 55);
				/*background: linear-gradient(144deg, rgba(247, 194, 28, .8) 50%, rgba(230, 35, 41, .8) 50%);*/
				background: linear-gradient(144deg, rgba(230, 36, 40, .8) 50%, rgba(255, 255, 255, 1) 50%);
				border: 1px solid #ccc;
			}

			&.day-start {
				background: rgb(87, 87, 86, 1);
				/*background: linear-gradient(144deg, rgba(230, 35, 41, .8) 50%, rgba(247, 194, 28, .8) 50%);*/
				background: linear-gradient(144deg, rgba(255, 255, 255, 1) 50%, rgba(230, 36, 40, .8) 50%);
				border: 1px solid #ccc;
			}
		}
	}
}

.form-group select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	position: relative;
	background: url(RESOURCE/img/dropdown-icon.png) no-repeat center right 12px;

}

.form-group select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	position: relative;
	background: url(RESOURCE/img/dropdown-icon.png) no-repeat center right 12px;

}

.form-group .checkbox {
	padding-left: 20px
}

.form-group .checkbox label {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding-left: 5px
}

.form-group .checkbox label::before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 17px;
	height: 17px;
	left: 0;
	margin-left: -20px;
	border: 1px solid #ccc;
	border-radius: 3px;
	background-color: #fff;
	-webkit-transition: border .15s ease-in-out, color .15s ease-in-out;
	-o-transition: border .15s ease-in-out, color .15s ease-in-out;
	transition: border .15s ease-in-out, color .15s ease-in-out
}

.form-group .checkbox label::after {
	display: inline-block;
	position: absolute;
	width: 16px;
	height: 16px;
	left: 0;
	top: 0;
	margin-left: -20px;
	padding-left: 3px;
	padding-top: 1px;
	font-size: 11px;
	color: #555
}

.form-group .checkbox input[type="checkbox"] {
	opacity: 0;
	z-index: 1
}

.form-group .checkbox input[type="checkbox"]:focus+label::before {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px
}

.form-group .checkbox input[type="checkbox"]:checked+label::after {
	font-family: 'FontAwesome';
	content: "\f00c"
}

.form-group .checkbox input[type="checkbox"]:disabled+label {
	opacity: .65
}

.form-group .checkbox input[type="checkbox"]:disabled+label::before {
	background-color: #eee;
	cursor: not-allowed
}

.form-group .checkbox.checkbox-circle label::before {
	border-radius: 50%
}

.form-group .checkbox.checkbox-inline {
	margin-top: 0
}

.form-group .checkbox input[type="checkbox"]:checked+label::before {
	background-color: #215d90;
	border-color: #215d90
}

.form-group .checkbox input[type="checkbox"]:checked+label::after {
	color: #fff
}


/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

.doc-box {
	color: #000;
}