@import "common.css";

.unit-view {
	padding-top: 140px;
	padding-bottom: 0;

	@media screen and (max-width: 1500px) {
		padding-top: 170px;
	}

	@media screen and (max-width: 989px) {
		padding-top: 160px;
	}

	.page-header {
		margin: 0px 0px 20px 0px;
		position: relative;

		h1 {
			font-size: 30px;
			color: #215d90;
			font-weight: 700;
			margin-top: 0px;
			padding-right: 60px;
		}

		.favIcon {
			position: absolute;
			right: 0px;
			top: 0px;
			font-size: 24px;
			color: #215d90;
			width: auto;
			padding: 0px;
			background: #ffffff;
			box-shadow: none;

			&:focus {
				box-shadow: none;
				outline: none;
			}
		}
	}

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.v-img-gallery {
		margin-top: 20px;
	}

	.unit-feedback-main {
		margin-top: 50px;
		margin-bottom: 30px;

		h2 {
			margin: 0 0 20px 0 !important;
		}

		#nofeedback {
			font-size: 18px;
		}

		.col-md-4 {
			@media all and (min-width: 992px) and (max-width: 1199px) {
				width: 40%;
			}
		}

		.col-md-8 {
			@media all and (min-width: 992px) and (max-width: 1199px) {
				width: 60%;
			}
		}

		.rating-gr-bl {
			background: #f2f6f8;
			background: linear-gradient(331deg, #f2f6f8, #eaeef1);
			box-shadow: 0 0 8px rgb(0 0 0 / 15%);
			max-width: 460px;
			margin-bottom: 30px;

			.rating-head {
				text-align: center;
				padding: 30px 20px;
				margin-bottom: 10px;

				h3 {
					margin-top: 0;
					font-family: var(--d-font2);
					font-weight: normal;
					font-size: 20px;
				}

				.rating-st-bl {
					display: inline-flex;
					max-width: 200px;
					text-align: center;
					border-radius: 20px;
					padding: 8px 20px;
					background-color: var(--white);
					font-size: 18px;
					font-family: var(--d-font2);
					margin: 15px 0 0;

					.stars {
						font-size: 16px;
						margin-top: 0;
						color: #215d90;
					}

					span {
						margin-right: 10px;
					}
				}

				.blue-link {
					color: var(--secondary);
					text-decoration: underline;
				}
			}

			.f_properties {
				display: block !important;

				.rating-row {
					padding: 18px 20px;
					border-top: 2px solid #e2e6e8;
				}

				.flex-align-center {
					-webkit-box-align: center;
					align-items: center;
					display: flex;
					-webkit-box-pack: justify;
					justify-content: space-between;
				}

				span {
					font-size: 15px;
					font-family: var(--d-font2);
				}

				.stars {
					font-size: 14px;
					margin-top: 0;
					color: #215d90 !important;

				}
			}
		}

		.feedbacks {
			margin: 0 !important;

			blockquote {
				border-left: 5px solid #215d90;

				p {
					font-size: 17px;
					margin-bottom: 10px;
				}

				footer {
					color: #215d90;
				}
			}
		}
	}

	.search-section {
		.searchbox {
			margin: 5px 1px 1px 1px;

			.search-popup-trigger {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				position: relative;
				background: url(RESOURCE/img/dropdown-icon.png) no-repeat center right 12px;
				padding: 10px 35px 10px 10px;
				height: auto;
				border-radius: 0px;
				border: 1px solid #215d90;
				font-size: 15px;
				color: #215d90;

			}
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.title-img {

		position: relative;
		width: 100%;
		height: 700px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}




	.main-section {
		position: relative;
		max-width: 1200px;

		.main-col {
			position: static;

			h2 {
				margin-top: 40px;
				font-size: 22px;
				color: #215d90;
				font-weight: 700;

				.stars {
					display: inline-block;
					margin-left: 22px;
					color: #dfb62d;
				}
			}

			.row.intro {
				margin: 20px 0px;
			}

			.owl-carousel-unit {
				position: relative;

				.img-thumbnail {
					border: none;
					padding: 0px;
					height: 550px;
					object-fit: cover;

					@media screen and (max-width: 1024px) {
						height: 465px;
					}

					@media screen and (max-width: 768px) {
						height: 344px;
					}

					@media screen and (max-width: 480px) {
						height: 235px;
					}
				}

				.owl-nav {
					position: absolute;
					top: 50%;
					margin-top: -20px;
					width: 100%;

					.owl-prev,
					.owl-next {
						background: #ffffff;
						font-size: 24px;
						line-height: 40px;
						width: 40px;
						height: 40px;
						text-align: center;
						position: absolute;
						opacity: 0.5;

						&:hover {
							opacity: 1;
						}
					}

					.owl-prev {
						left: 15px;
					}

					.owl-next {
						right: 15px;
					}
				}
			}



			.propertiesList {
				h3 {
					margin-top: 40px;
					font-size: 22px;
					color: #215d90;
					font-weight: 700;
				}

				.btn.btn-go {
					margin-top: 20px;
				}

				.properties,
				.allProperties {
					padding: 0px;

					.prop {
						background: url(RESOURCE/img/list-arrow.png) no-repeat left center;
						padding-left: 22px;
						margin: 5px 0px;
						font-size: 15px;
						line-height: 24px;

						&.missing {
							opacity: 0.6;
							background: url(RESOURCE/img/no-list-icon.png) no-repeat left center;
						}
					}

					.col-sm-4 {
						background: url(RESOURCE/img/list-arrow.png) no-repeat left center;
						padding-left: 18px;
						margin: 2px 0px;
						font-size: 15px;
						line-height: 24px;

						.fa {
							display: none;
						}

						&.missing {
							opacity: 0.6;
							background: url(RESOURCE/img/no-list-icon.png) no-repeat left center;
						}

						@media screen and (max-width: 989px) {
							width: 50%;
						}

						@media screen and (max-width: 767px) {
							width: 100%;
						}
					}
				}
			}

			.daterange {
				margin-top: 20px;
			}

			.stars {
				margin-top: 10px;
			}

			.description {
				padding: 15px 15px 5px 15px;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {

				@mixin columnlayout 2;

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					/*text-decoration: line-through;*/
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		.sidebar {
			position: relative;
			z-index: 12;
		}



		.info-col {
			padding: 0px;
			position: -webkit-sticky;
			position: sticky;
			top: 10px;


			.info-col-header {
				height: 40px;
				padding: 1px;
				background-color: #215d90;
				color: #fff;
				text-align: center;

				h2 {
					margin-top: 10px;
					font-size: 18px;
				}
			}

			.info-col-main {
				border: 1px solid #215d90;
				border-bottom: none;
				width: 100%;
			}

			.backlink,
			.contactlink {
				margin: 20px 0px 0px 0px;

				a {
					background: #215d90;
					padding: 12px;
					display: block;
					font-size: 18px;
					color: #ffffff;
					text-align: center;
					text-decoration: none;

					&:hover {
						background: #f7c21c;
					}
				}

			}

			.service-title {
				.btn.btn-warning {
					background: #215d90;
					border: solid 1px #215d90;
				}
			}

			.subprice {
				width: 100%;
				border: 1px solid #215d90;
				border-top: none;
			}

			.fav-section {
				margin-top: 20px;
				border: none !important;
				padding: 0px;

				.btn.btn-block {
					background: #ffffff;
					padding: 12px;
					display: block;
					font-size: 18px;
					color: #215d90;
					text-align: center;
					text-decoration: none;
					border: solid 2px #215d90;
					box-shadow: none;
					border-radius: 0px;
				}

				.fa {
					margin-right: 8px;
				}

				.fa-heart {
					color: #215d90;
				}
			}

			.rightMap {
				margin-top: 20px;
			}

			.fontactFormBtn {
				margin-top: 20px;

				a {
					width: 100%;
					padding: 12px 15px;
				}
			}

			@media screen and (max-width: 989px) {
				margin-top: 40px;
			}

			@media screen and (max-width: 767px) {
				.asd__wrapper--full-screen {
					top: 120px;
					overflow: auto;
					z-index: 12345;
				}
			}

			@media screen and (max-width: 480px) {
				.box.full-xxs {
					top: 120px !important;
				}
			}

		}


		.info-col {

			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 1px 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

				.flow-buttons {
					button {
						margin-left: 20px;
					}
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 470px;
			width: 100%;

			@media (max-width:420px) {
				height: 510px;
			}

			.mapnote {
				font-size: 14px;
				padding: 0 0 20px;
				background: #fff;

			}

			>div {
				height: 350px;

				@media (max-width:991px) {
					height: 400px;
				}
			}

			&.no-map {
				height: auto;

				.mapconsent {
					height: auto;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: rgba(26, 121, 187, 0.15);
					flex-direction: column;
					padding: 15px;

					.doc-box {}

					.mapconsent-button {
						background: var(--primarycolor);
						color: #fff;
						border-radius: 5px;
						padding: 0 15px 0 15px;
						height: 36px;
						display: block;
						line-height: 35px;

						&:hover {
							background: var(--secondarycolor);
						}
					}
				}

				@media (max-width:420px) {
					height: auto;
				}

				>div {
					height: auto;

					@media (max-width:991px) {
						height: auto;
					}
				}
			}
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}

	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.page-header h1 {
			font-size: 22px;
		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}




}


.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}

}

.unit-proposals {
	background-color: #f8f8f8;
}