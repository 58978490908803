p {
	/*word-break: break-all;*/
}

.staticBanner {
	background: url(RESOURCE/img/static-banner.jpg) no-repeat center;
	background-size: cover;
	height: 350px;
}

.drundgange {
	background: url(RESOURCE/img/banner-drundgange.jpg) no-repeat center;
	background-size: cover;
	height: 350px;
}

.aparthotel-bottom-unit {
	.single-unit {
		padding: 10px !important;
		box-shadow: 0 0 5px rgba(0, 0, 0, .25);
		width: 100%;
		margin-top: 30px !important;

		h5 {
			color: #215d90;
			font-weight: bold;
			font-size: 18px;
		}

		iframe {
			width: 100%;
			height: 500px;

			@media screen and (max-width: 767px) {
				height: 280px;
			}
		}

		a {
			color: #fff !important;
		}
	}
}


.staticBanner.travelinfo {
	background: url(RESOURCE/img/lastminute.jpg) no-repeat 50% 60%;
	background-size: cover;
	height: 350px;
}

.staticBanner.kontakt {
	background: url(RESOURCE/img/kontakt.jpg) no-repeat 50% 45%;
	background-size: cover;
	height: 350px;
}

.staticBanner.impressum {
	background: url(RESOURCE/img/banner-impressum.jpg) no-repeat 50% 50%;
	background-size: cover;
	height: 350px;
}

.staticBanner.region {
	background: url(RESOURCE/img/banner-region.jpg) no-repeat 50% 50%;
	background-size: cover;
	height: 350px;
}


.notFound {
	min-height: 600px;
}

.ct-form-section {
	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;

		a {
			background: var(--primarycolor);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--secondarycolor);
			}
		}
	}
}

.staticPages {
	padding: 45px 0px;
	font-size: 15px;
	line-height: 26px;

	.page-header {
		margin: 0px 0px 20px 0px;
		position: relative;
		border-bottom: 1px solid #dddddd;
	}

	h1 {
		font-size: 36px;
		line-height: 46px;
		color: #225ca8;
		font-weight: 600;
	}

	h2 {
		font-size: 28px;
		line-height: 38px;
		color: #215d90;
		font-weight: 700;
	}

	@media screen and (max-width: 989px) {
		h1 {
			font-size: 32px;
			line-height: 40px;
		}

		h2 {
			font-size: 28px;
			line-height: 40px;
		}
	}

	@media screen and (max-width: 767px) {
		h1 {
			font-size: 24px;
			line-height: 32px;
		}

		h2 {
			font-size: 22px;
			line-height: 30px;
		}
	}

	.subTxt {
		font-size: 20px;
	}

	.wirSind {
		padding: 0px 20%;
		text-align: left;

		.col-sm-6 {
			padding: 0px;
		}
	}

	.officeImg {
		img {
			max-width: 100%;
			height: auto;
		}
	}

	.leftRightCont {
		padding: 30px 0px;
		font-size: 16px;
		line-height: 28px;


		h2 {
			font-size: 28px;
			line-height: 38px;
			color: #215d90;
			font-weight: 700;
			margin: 0px 0px 15px 0px;

			small {
				font-size: 14px;
				line-height: 28px;
				color: #f7c21c;
				font-weight: 700;
				text-transform: uppercase;
				display: block;
			}
		}

		img {
			max-width: 100%;
		}
	}

	.contact-form {
		margin: 20px 0px;

		.form-group {
			label {
				text-align: left;
				display: block;
				font-weight: 600;
				font-size: 14px;
				color: #222222;
				margin: 0px 0px 5px 0px;

				span {
					font-size: 16px;
					color: #ff0000;
					font-weight: 400;
				}
			}

			.form-control {
				border-radius: 0px;
				padding: 8px 12px;
				height: auto;
			}

			.checkbox label:before {
				top: 5px;
			}

			.checkbox {
				label {
					display: inline-block;
				}
			}
		}

		.btn.btn-go {

			padding: 12px 15px 12px 30px;
			border-radius: 0px;
			font-weight: 700;

			.fas {
				margin-left: 20px;
			}
		}
	}

	.button {
		background: #008a97;
		padding: 10px 60px;
		font-size: 18px;
		font-weight: 600;
		color: #ffffff;
		line-height: 30px;
		text-decoration: none;
		margin-top: 20px;
		display: inline-block;
	}

	.topList {

		.unit-block {
			background-image: linear-gradient(-45deg, #d8e6f1, #ffffff);
			padding: 20px;
			margin: 15px 0px;
		}

		h6 {
			font-size: 16px;
			font-weight: 600;
			line-height: 22px;
			min-height: 44px;
			margin: 0px 0px 10px 0px;

			a {
				text-decoration: none;
			}
		}

		.image-block {
			img {
				border: solid 5px #ffffff;
			}
		}

		.unit-cont-home {
			margin-top: 15px;
		}

		@media screen and (max-width: 1200px) {
			h6 {
				min-height: 52px;
			}
		}

		@media screen and (max-width: 767px) {
			h6 {
				min-height: 26px;
			}
		}
	}

	.accordion {
		margin-top: 45px;

		.card {
			border: solid 1px #cccccc;
			margin: 15px 0px;

			h2 {
				margin: 0px;
				padding: 0px 0px 0px 0px;
				position: relative;

				.btn {
					display: block;
					width: 100%;
					padding: 15px 10px 15px 60px;
					font-size: 16px;
					font-weight: 600;
					text-align: left;
					text-decoration: none;
					color: #008a97;

					&:before {
						content: "-";
						position: absolute;
						top: 12px;
						left: 18px;
						font-size: 22px;
						width: 30px;
						height: 30px;
						text-align: center;
						line-height: 25px;
						border: solid 1px #008a97;
						border-radius: 50%;
					}

					&:focus {
						outline: none;
					}

					&.collapsed {
						color: #222222;

						&:before {
							content: "+";
							border: solid 1px #222222;
						}
					}
				}

			}

			.card-body {
				padding: 0px 20px 20px 20px;
				font-size: 16px;
				line-height: 26px;
			}
		}
	}

	.rentalLeft {
		iframe {
			margin-bottom: 30px;
		}

		h2 {
			margin-top: 0px;
			margin-bottom: 20px;
		}
	}

	.rentalRight {
		img {
			margin-bottom: 30px;
		}

		h2 {
			margin-top: 0px;
			margin-bottom: 20px;
		}
	}

	@media screen and (max-width: 1200px) {
		.rentalLeft {
			iframe {
				height: 300px;
			}

			h2 {
				font-size: 24px;
			}
		}

		.rentalRight {
			img {
				height: 300px;
				width: auto;
			}

			h2 {
				font-size: 24px;
			}
		}
	}

	@media screen and (max-width: 989px) {
		.rentalLeft {
			iframe {
				height: 250px;
			}

			h2 {
				font-size: 18px;
				line-height: 30px;
			}
		}

		.rentalRight {
			img {
				height: 250px;
			}

			h2 {
				font-size: 18px;
				line-height: 30px;
			}
		}
	}

	@media screen and (max-width: 767px) {
		.rentalRight {
			text-align: left;

			.wirSind {
				padding: 0px;

				.col-sm-6 {
					float: left;
					width: 30%;
				}
			}
		}
	}

	@media screen and (max-width: 639px) {
		.rentalRight {

			.wirSind {
				.col-sm-6 {
					width: 50%;
				}
			}
		}
	}

}

.travel-info {
	.right {
		img {
			max-width: 100%;
		}
	}
}